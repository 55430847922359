var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-auto offcanvas-mobile w-300px w-xl-350px",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex justify-content-end"}),_c('div',{staticClass:"row py-3"},[_c('div',{staticClass:"col-md-4 col-12 d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-lg-70 details-symbol"},[_c('div',{staticClass:"symbol-label symbol-label-profile",style:(_vm.user.profile_picture && _vm.user.profile_picture.length !== 0
                      ? 'background-image: url(' +
                        _vm.profilePictureBase64 +
                        '); background-color: #ffffff;'
                      : 'media/users/blank.png')})])]),_c('div',{staticClass:"col-md-8 col-12 row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"font-weight-bolder font-size-h5 text-dark-75"},[(!_vm.user.id)?_c('b-skeleton',{attrs:{"type":"text","height":"1.5rem"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.user.name))])],1),_c('div',{staticClass:"text-muted"},[(!_vm.user.id)?_c('b-skeleton',{attrs:{"type":"text","width":"60%"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.user.email))])],1)])])]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded"},_vm._l((_vm.tabNavigation),function(item,i){return _c('div',{key:i,staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: item.route }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"navi-link py-4",class:{ active: isExactActive },attrs:{"href":href}},[(item.icon)?_c('span',{staticClass:"navi-icon"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t(item.label)))])])]}}],null,true)})],1)}),0)])])]),_c('div',{staticClass:"flex-row-fluid ml-lg-8 client-details"},[(_vm.$route.name === 'projectSettingsUsersAccessInformation')?_c('userInformation',{on:{"user-updated":_vm.loadUser}}):(
          _vm.$route.name === 'projectSettingsUsersAccessData'
        )?_c('userAccessData'):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }