<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      user.profile_picture && user.profile_picture.length !== 0
                        ? 'background-image: url(' +
                          profilePictureBase64 +
                          '); background-color: #ffffff;'
                        : 'media/users/blank.png'
                    "
                  ></div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton v-if="!user.id" type="text" height="1.5rem" />
                    <span>{{ user.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton v-if="!user.id" type="text" width="60%" />
                    <span>{{ user.email }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <userInformation
          v-if="$route.name === 'projectSettingsUsersAccessInformation'"
          @user-updated="loadUser"
        />
        <userAccessData
          v-else-if="
            $route.name === 'projectSettingsUsersAccessData'
          "
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import Users from "@/components/Projects/Settings/Users/users";
import userInformation from "@/components/Projects/Settings/Users/Information";
import userAccessData from "@/components/Projects/Settings/Users/AccessData";
import { bus } from "@/main";

export default {
  name: "ProjectUsersDetails",
  components: {
    userInformation,
    userAccessData
  },
  data() {
    return {
      profilePictureBase64: "media/users/blank.png",
      user: {
        profile_picture: []
      },
      tabIndex: 0,
      tabNavigation: [
        {
          route: "projectSettingsUsersAccessInformation",
          label: "users.baseData",
          icon: "fal fa-user"
        },
        {
          route: "projectSettingsUsersAccessData",
          label: "users.accessData",
          icon: "fal fa-key"
        }
      ]
    };
  },
  mounted() {
    if (this.$route.params.id !== toString(this.user.id)) this.loadUser();
    bus.$on("userUpdated", this.loadUser);
  },
  destroyed() {
    bus.$off("userUpdated", this.loadUser);
  },
  methods: {
    loadUser() {
      let userId = this.$route.params.id;
      Users.get(userId)
        .then(response => {
          this.user = response.data.data;

          if (this.user.profile_picture) {
            this.profilePictureBase64 =
              "data:" +
              this.user.profile_picture.mimeType +
              ";base64," +
              this.user.profile_picture.base64;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>
