<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("users.baseData") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("users.updateBaseDataAndProfilePicture") }}
        </span>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary ml-2 mr-2"
          @click="$router.push({ name: appVersion < 2 ? 'tenantUserAdministration' : 'projectSettingsUsers' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="saveButtonDisabled" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          v-show="!saveButtonDisabled"
          class="btn btn-primary"
          @click="saveUser"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("users.profilePicture") }}:
            </h3>

            <div class="form-group row align-items-center">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="user-avatar-upload">
                  <div
                    v-show="$refs.upload && $refs.upload.dropActive"
                    class="drop-active"
                  >
                    <h3>{{ $t("users.dropFilesToUpload") }}</h3>
                  </div>
                  <div v-show="!edit" class="avatar-upload">
                    <div class="text-center p-2">
                      <label for="avatar">
                        <img
                          :src="
                            files.length ? files[0].url : profilePictureBase64
                          "
                          class="rounded-circle"
                        />
                        <h4 class="pt-2">
                          {{ $t("users.dropFilesAnywhereToUpload") }}
                        </h4>
                      </label>
                    </div>
                    <div class="text-center p-2">
                      <file-upload
                        ref="upload"
                        v-model="files"
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        :drop="!edit"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                      >
                        {{ $t("users.uploadProfilePicture") }}
                      </file-upload>
                      <button
                        v-show="
                          files.length ||
                          profilePictureBase64 !== 'media/users/blank.png'
                        "
                        type="button"
                        class="btn btn-secondary m-2"
                        @click.prevent="remove"
                      >
                        {{ $t("users.removeProfilePicture") }}
                      </button>
                    </div>
                  </div>

                  <div v-show="files.length && edit" class="avatar-edit">
                    <div v-if="files.length" class="avatar-edit-image">
                      <img
                        v-if="files[0].url"
                        ref="editImage"
                        :src="files[0].url"
                      />
                    </div>
                    <div class="text-center p-4">
                      <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click.prevent="$refs.upload.clear"
                      >
                        {{ $t("general.cancel") }}
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary m-2"
                        @click.prevent="editSave"
                      >
                        {{ $t("users.cropProfilePicture") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("users.baseData") }}:
            </h3>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.salutation") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="user.core_data.salutation"
                  class="form-control"
                  :placeholder="$t('users.enterSalutation')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.title") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="user.core_data.title"
                  class="form-control"
                  :placeholder="$t('users.enterTitle')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.firstName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userFirstName"
                  v-model="user.core_data.firstname"
                  :placeholder="$t('users.enterFirstName')"
                  aria-describedby="userFirstName-help userFirstName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.core_data.firstname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.core_data.firstname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.firstname.required"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.required.firstname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.firstname.minLength"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.lastName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userLastName"
                  v-model="user.core_data.lastname"
                  :placeholder="$t('users.enterLastName')"
                  aria-describedby="userLastName-help userLastName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.core_data.lastname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.core_data.lastname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.lastname.required"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.required.lastname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.core_data.lastname.minLength"
                  id="userLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.email") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="userEmail"
                  v-model="user.email"
                  :placeholder="$t('users.enterEmail')"
                  aria-describedby="userEmail-help userEmail-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.user.email.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.email.email
                      ? 'is-invalid'
                      : 'is-valid' && !$v.user.email.isUnique
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.required"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.required.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.email"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.email.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.user.email.isUnique"
                  id="userEmail-feedback"
                >
                  {{ $t("validation.unique.email") }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("general.active") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <span class="switch switch-sm">
                  <label>
                    <input v-model="user.active" type="checkbox" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.language") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-4">
                <v-select
                  v-model="user.language_id"
                  class="form-control"
                  :items="languageOptions"
                  item-text="label"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                />
                <b-form-text>{{ $t("users.selectLanguage") }}</b-form-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<style lang="scss">
.user-avatar-upload .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.user-avatar-upload .text-center .btn {
  margin: 0 0.5rem;
}
.user-avatar-upload .avatar-edit-image {
  max-width: 100%;
}
.user-avatar-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.user-avatar-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.cropper-container {
  max-width: calc(100% + 30px);
}
</style>

<script>
import Users from "@/components/Projects/Settings/Users/users";
import Languages from "@/components/General/Languages/languages";

import { email, required, minLength } from "vuelidate/lib/validators";
import { bus } from "@/main";

import FileUpload from "vue-upload-component";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_USER_DATA } from "@/core/services/store/profile.module";
import Auth from "@/components/General/Auth/auth";

export default {
  name: "ProjectUsersInformation",
  components: {
    FileUpload
  },
  watch: {
    edit(value) {
      if (value) {
        this.saveButtonDisabled = true;

        this.$nextTick().then(() => {
          if (!this.$refs.editImage) {
            return;
          }

          var cropper_opts = {
            aspectRatio: 1 / 1,
            viewMode: 1,
            crop: function () {}, //to show the crop box manually
            minCanvasWidth: 200,
            minCanvasHeight: 200,
            minCropBoxWidth: 100,
            minCropBoxHeight: 100,
            minContainerWidth: 200, //decides the size of image
            minContainerHeight: 200, //decides the size of image
            autoCropArea: 1,
            modal: true, // Show the black modal
            guides: true, // Show the dashed lines for guiding
            center: true, // Show the center indicator for guiding
            highlight: true, // Show the white modal to highlight the crop box
            background: true, // Show the grid background,
            scalable: true,
            rotatable: true,
            checkOrientation: true,
            cropBoxResizable: true,
            dragMode: "move"
          };

          let cropper = new Cropper(this.$refs.editImage, cropper_opts);
          this.cropper = cropper;
        });
      } else {
        this.saveButtonDisabled = false;

        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    }
  },
  validations: {
    user: {
      core_data: {
        firstname: { required, minLength: minLength(3) },
        lastname: { required, minLength: minLength(3) }
      },
      email: {
        email,
        required,
        isUnique(value) {
          return !this.currentUsers.includes(value);
        }
      }
    }
  },
  data() {
    return {
      isBusy: false,
      saveButtonDisabled: false,
      files: [],
      edit: false,
      cropper: false,
      profilePictureBase64: "media/users/blank.png",

      user: {
        email: "",
        active: false,
        language_id: "",
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        },
        profile_picture: ""
      },
      currentUsers: [],
      languageOptions: []
    };
  },
  mounted() {
    this.loadUser();
    this.getLanguages();
  },
  methods: {
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick().then(() => {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("users.notAPicture")
          });
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    remove() {
      this.files = [];
      this.profilePictureBase64 = "media/users/blank.png";
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonDisabled = this.$v.$invalid;
    },
    loadUser() {
      var userId = this.$route.params.id;
      Users.get(userId)
        .then(response => {
          this.setUser(response.data.data);
          this.getUsers();
          this.validateFields();
        })
        .catch(e => {
          console.log(e);
        });
    },
    setUser(user) {
      this.user = user;
      if (!user.core_data) {
        this.user.core_data = {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        }
      }

      if (user.profile_picture && user.profile_picture.length !== 0) {
        this.profilePictureBase64 =
          "data:" +
          user.profile_picture.mimeType +
          ";base64," +
          user.profile_picture.base64;
      }

      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.tenantUserAdministration"),
          route: ""
        },
        {
          title: this.$t("general.overview"),
          route: "/tenant/users/"
        },
        {
          title: user.core_data.firstname,
          route: "/tenant/users/" + user.id + "/information"
        }
      ]);
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            self.user.language_id = data.id;
            self.selectedLanguageLabel = data.label;
          });
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getUsers() {
      let params = {
        page: 1,
        size: 100
      };
      Users.getAll(params)
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            if (self.user.email !== data.email) {
              self.currentUsers.push(data.email);
            }
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    saveUser() {
      this.isBusy = true;

      let updateData = {
        name: this.user.core_data.firstname + ' ' + this.user.core_data.lastname,
        email: this.user.email,
        active: this.user.active ? 1 : 0,
        core_data: this.user.core_data,
        language_id: this.user.language.id
      };

      /*
      let form = new FormData();

      Object.entries(postData).forEach(([key, value]) => {
        form.append(key, value);
      });

      if (this.files.length) {
        form.append("profile_picture", this.files[0].file);
      }

      if (this.profilePictureBase64 === "media/users/blank.png") {
        form.append("profile_picture", 0);
      }

       */

      Users.update(this.user.id, updateData)
        .then(response => {
          let user = response.data.data;
          this.setUser(user);
          this.$store.dispatch(UPDATE_USER_DATA, user);
          Auth.updateUserData(user);
          this.$toast.fire({
            icon: "success",
            title: this.$t("users.userUpdated")
          });
          this.isBusy = false;
          bus.$emit("userUpdated");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });

          this.isBusy = false;
        });
    }
  }
};
</script>
