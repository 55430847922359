<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("users.profile") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("users.updateProfile") }}
        </span>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: appVersion < 2 ? 'tenantUserAdministration' : 'projectSettingsUsers' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="saveButtonDisabled" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          v-show="!saveButtonDisabled"
          class="btn btn-primary"
          @click="saveUser"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("users.password") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <b-form-input
              id="userPassword"
              v-model="password"
              type="password"
              :placeholder="$t('users.enterPassword')"
              aria-describedby="userPassword-help userPassword-feedback"
              trim
              class="form-control"
              :class="passwordValidationClass"
              @input="validateFields(3)"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.password.minLength">
              {{ $t("validation.minChars", { chars: 8 }) }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.password.alphaNum">
              {{ $t("validation.alphaNum") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.password.upLowCase">
              {{ $t("validation.upLowCase") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("users.passwordConfirmation") }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <b-form-input
              id="userPasswordConfirmation"
              v-model="passwordConfirmation"
              type="password"
              :placeholder="$t('users.enterPasswordConfirmation')"
              aria-describedby="userPasswordConfirmation-help userPasswordConfirmation-feedback"
              trim
              class="form-control"
              :class="
                !$v.passwordConfirmation.sameAs
                  ? 'is-invalid'
                  : password.length > 1
                  ? 'is-valid'
                  : ''
              "
              @input="validateFields(3)"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.passwordConfirmation.sameAs"
              id="userPasswordConfirmation-feedback"
            >
              {{ $t("validation.samePassword") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("users.role") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <v-select
              v-if="roleOptions"
              v-model="user.role_id"
              class="form-control custom"
              :items="roleOptions"
              item-text="role"
              item-value="id"
              :label="$t('users.selectRole')"
              multiple
              single-line
              :menu-props="{ offsetY: true }"
            ></v-select>
            <b-form-text>{{ $t("users.selectRole") }}</b-form-text>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>

<script>
import Users from "@/components/Projects/Settings/Users/users";

import { minLength, sameAs, helpers } from "vuelidate/lib/validators";
import { bus } from "@/main";
import Roles from "@/components/Admins/Roles/roles";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const alphaNum = helpers.regex(
  "alphaNum",
  /^(?=.*[a-zA-Z])(?=.*[0-9])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]+$/
);
const upLowCase = helpers.regex(
  "upLowCase",
  /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1}).*/
);

export default {
  name: "ProjectUsersAccessData",
  validations: {
    password: { minLength: minLength(8), alphaNum, upLowCase },
    passwordConfirmation: {
      sameAs: sameAs("password")
    }
  },
  data() {
    return {
      isBusy: false,
      saveButtonDisabled: false,
      roleOptions: [],
      user: {
        language_id: "",
        role_id: []
      },
      password: "",
      passwordConfirmation: ""
    };
  },
  computed: {
    passwordValidationClass() {
      if (this.password.length === 0) {
        return "";
      }
      if (
        !this.$v.password.minLength ||
        !this.$v.password.alphaNum ||
        !this.$v.password.upLowCase
      ) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  mounted() {
    this.getRoles();
    this.loadUser();
  },
  methods: {
    validateFields() {
      this.$v.$touch();
      this.saveButtonDisabled = this.$v.$invalid;
    },
    loadUser() {
      var userId = this.$route.params.id;
      Users.get(userId)
        .then(response => {
          this.user = response.data.data;
          this.user.language_id = this.user.language.id;
          this.user.core_data = JSON.parse(this.user.core_data);
          this.user.role_id = [];
          let self = this;
          if (this.user.role.length) {
            this.user.role.forEach(function (data) {
              self.user.role_id.push(data.id);
            });
          }

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminTenants"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/tenant/users/"
            },
            {
              title: this.user.core_data.firstname,
              route: "/tenant/users/" + this.user.id + "/profile"
            }
          ]);

          this.validateFields();
        })
        .catch(e => {
          console.log(e);
        });
    },
    getRoles() {
      let params = {
        page: 1,
        size: 100
      };
      Roles.getAll(params)
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            let tmpObj = {
              id: data.id,
              role: data.name
            };
            self.roleOptions.push(tmpObj);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    saveUser() {
      this.isBusy = true;

      let updateData = {
        name: this.user.core_data.firstname,
        email: this.user.email,
        active: this.user.active,
        language_id: this.user.language.id,
        role_id: this.user.role_id
      };

      if (this.password) {
        updateData.new_password = this.password;
      }

      Users.update(this.user.id, updateData)
        .then(() => {
          this.loadUser();
          this.$toast.fire({
            icon: "success",
            title: this.$t("users.userUpdated")
          });
          this.isBusy = false;
          bus.$emit("userUpdated");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
